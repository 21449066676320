import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { useHomeBlog } from '../hooks/useBlogHome';

const Blog = () => {
	const blogPosts = useHomeBlog();

	return (
		<>
			<section className="blog">
				{blogPosts.map((post, i) => (
					<div className="background_image_wrapper" key={post.id}>
						<Img className="blog_image" fluid={post.featured_media.localFile.childImageSharp.fluid} />
						<div className="text_wrapper">
							<h2 dangerouslySetInnerHTML={{ __html: post.title }} />
							<div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
							<Link to={post.slug}>Read More</Link>
						</div>
						<div className="post_tags">
							{post.categories.map((tag, i) => (
								<Link to={`/category/${tag.slug}`}>#{tag.name}</Link>
							))}
						</div>
					</div>
				))}
			</section>
		</>
	);
};

export default Blog;

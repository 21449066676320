import React from 'react';
// import SEO from '../components/seo';
import Header from '../components/header';
import Footer from '../components/Footer';
import Blog from '../components/Blog';
import SEO from '../components/seo';
import SeoImg from '../images/yoda.png';
import '../style/app.scss';

const Homepage = () => (
	<>
		<SEO title="Andreja Mahovic" description="Personal blog of Andreja Mahovic" metaImage={SeoImg} />
		<Header />
		<Blog />
		<Footer />
	</>
);

export default Homepage;

import { useStaticQuery, graphql } from 'gatsby';

export const useHomeBlog = () => {
	const HomePosts = useStaticQuery(
		graphql`
			query GET_HOME_POSTS {
				allWordpressPost {
					nodes {
						featured_media {
							localFile {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid
									}
									fixed {
										...GatsbyImageSharpFixed
									}
								}
							}
						}
						excerpt
						slug
						title
						id
						categories {
							name
							slug
						}
					}
				}
			}
		`
	);
	return HomePosts.allWordpressPost.nodes;
};
